@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

.error-boundary {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.error-boundary,
.text {
    text-align: center;
    padding-top: 45px;
    top: 20%;
    width: 100%;
    margin: auto;
    font-family: "Poppins";
}